import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import "./tailwind.css";
import "@xyflow/react/dist/style.css";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useActionData,
  useNavigation,
  useRouteError,
} from "@remix-run/react";
import "@fontsource-variable/inter";
import { json, LoaderFunctionArgs } from "@remix-run/node";
import { publicEnv } from "~/utils/env.server";
import { PresignedPUTUrlsProvider } from "~/context/PresignedPUTUrlsContext";
import { FileUploadProvider } from "~/context/FileUploadContext";
import { TooltipProvider } from "~/components/ui/tooltip";
import { Toaster } from "~/components/ui/sonner";
import { useNonce } from "~/context/Nonce";
import { GeneralErrorBoundary } from "~/components/custom/GeneralErrorBoundary";
import { ReactNode, useEffect, useRef, useState } from "react";
import { UrlUploadProvider } from "~/context/UrlUploadContext";
import { cn } from "~/utils";

// export default function App() {
//   return <Outlet />;
// }

export async function loader(args: LoaderFunctionArgs) {
  return json({
    publicEnv,
  });
}

export async function action() {
  return json({});
}

function Document({
  // nonce,
  children,
}: {
  // nonce: string;
  children: ReactNode;
}) {
  const actionData = useActionData();
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(ref.current.getAnimations().map(({ finished }) => finished)).then(
      () => !active && setAnimationComplete(true),
    );
  }, [active]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-title" content="ADAPTS" />
        <link rel="manifest" href="/site.webmanifest" />

        <Meta />
        <Links />
      </head>

      <body>
        {/* navigation progress bar */}
        <div
          role="progressbar"
          aria-hidden={!active}
          aria-valuetext={active ? "Loading" : undefined}
          className="fixed inset-x-0 left-0 top-0 z-50 h-1 animate-pulse"
        >
          <div
            ref={ref}
            className={cn(
              "h-full bg-gradient-to-r from-blue-500 to-blue-600 transition-all duration-500 ease-in-out",
              navigation.state === "idle" && animationComplete && "w-0 opacity-0 transition-none",
              navigation.state === "submitting" && "w-4/12",
              navigation.state === "loading" && "w-10/12",
              navigation.state === "idle" && !animationComplete && "w-full",
            )}
          />
        </div>

        {/* providers and content */}
        <FileUploadProvider>
          <UrlUploadProvider>
            <PresignedPUTUrlsProvider>
              <TooltipProvider>
                {/*  */}
                {children}
                {/*  */}
              </TooltipProvider>
            </PresignedPUTUrlsProvider>
          </UrlUploadProvider>
        </FileUploadProvider>

        {/* sonner */}
        <Toaster richColors />

        {/* Remix tags */}
        <ScrollRestoration />
        {/* <Scripts nonce={nonce} /> */}
        <Scripts />
      </body>
    </html>
  );
}

function AppLayout() {
  // const nonce = useNonce();

  return (
    // <Document nonce={nonce}>
    (<Document>
      <Outlet />
    </Document>)
  );
}

export default withSentry(AppLayout);

// from epic-stack
export function ErrorBoundary() {
  const error = useRouteError();captureRemixErrorBoundaryError(error);
  // the nonce doesn't rely on the loader so we can access that
  // const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    // <Document nonce={nonce}>
    (<Document>
      <GeneralErrorBoundary />
    </Document>)
  );
}